/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */

import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { ReactComponent as DrawerMenuIcon } from 'assets/drawer-menu-icon.svg';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { LogoutUser } from 'store/store';
import { TProps_SideBar } from 'typings';

export const getRouteHeadingMapping = (
  t: TFunction<'translation', 'layout.topBar'>
) => {
  return [
    {
      route: '/dashboard',
      heading: t('dashboard')
    },
    {
      route: '/userAccount',
      heading: t('account')
    },
    {
      route: '/subscriptions',
      heading: t('subscription')
    },
    {
      route: '/subscription',
      heading: t('subscription')
    },
    {
      route: '/billings',
      heading: t('billing')
    },
    {
      route: '/ourPlans',
      heading: t('ourPlans')
    },
    {
      route: '/reviewPurchase',
      heading: t('reviewPlan')
    },
    {
      route: '/frequently-asked-questions',
      heading: t('faq')
    }
  ];
};

function TopBar(props: TProps_SideBar) {
  const { setOpenSideBar, openSideBar } = props;
  const location = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'layout.topBar'
  });
  const [heading, setHeading] = useState(t('userPortal'));

  const routeHeadingMapping = getRouteHeadingMapping(t);

  const getHeading = () =>
    routeHeadingMapping.find((el) => location.pathname.search(el.route) >= 0)
      ?.heading;

  const { userDetails } = useAppSelector((state) => state.user);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  useEffect(() => {
    const header = getHeading();
    if (header) {
      setHeading(header);
    }
  }, [location.pathname]);

  const handleOpenUserMenu = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogOut = async () => {
    try {
      LogoutUser();
    } catch (err) {
      console.error(err);
    }
  };

  if (!openSideBar || !isMobile) {
    return (
      <>
        <Stack
          sx={{
            flexDirection: isMobile ? 'row' : 'row-reverse',
            padding: '16px 20px',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.background.default,
            top: 0,
            width: '100%',
            zIndex: 1299,
            minHeight: (theme) => theme.spacing(10)
          }}
        >
          <Tooltip
            title={`${userDetails?.name.first} ${userDetails?.name.last}`}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              className="btn-no-focus"
            >
              <Avatar>{userDetails?.name.first[0]}</Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={onLogOut}>
              <Typography textAlign="center">{t('logout')}</Typography>
            </MenuItem>
          </Menu>

          <Typography variant="h3">{heading}</Typography>

          {isMobile &&
            (location.pathname === '/addCameraInfo' ? (
              <div />
            ) : (
              <>
                {!openSideBar ? (
                  <IconButton
                    onClick={() => setOpenSideBar(true)}
                    sx={{ p: 0 }}
                    // sx={{ width: 30, height: 30, border: '2px solid #DBDBDB' }}
                  >
                    <DrawerMenuIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setOpenSideBar(false)}
                    sx={{
                      background:
                        'linear-gradient(143.2deg, #DEDEDE 7.09%, #F5F5F5 89.29%, #FFFFFF 89.29%)',
                      borderRadius: '50%'
                    }}
                  >
                    <CloseIcon sx={{ stroke: '#DBDBDB' }} />
                  </IconButton>
                )}
              </>
            ))}
        </Stack>
        <Divider />
      </>
    );
  }

  return null;
}

export default TopBar;
