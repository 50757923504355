/* eslint-disable import/no-extraneous-dependencies */
import { Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { initializeStripe, stripeOptions } from 'configs/stripeConfig';
import { homeSecurityPortalApi } from 'data/axios';
import { stripeLocaleMapping } from 'i18n/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints } from 'services/api/endpoints';
import { TProps_PaymentScreen } from 'typings';
import StripeForm from './StripeForm';

function PaymentScreenDemo(props: TProps_PaymentScreen) {
  const { i18n } = useTranslation();
  const {
    submitPurchaseRequest,
    amount,
    isUpgrade,
    isFreeTrial,
    isCancellationAndRefundPolicyAccepted
  } = props;
  const [options, setOptions] = useState(stripeOptions);
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const initializeStripeInstance = async () => {
    const stripeInstance = await initializeStripe();
    setStripe(stripeInstance);
  };

  const stripeLocale =
    stripeLocaleMapping[i18n.language as keyof typeof stripeLocaleMapping] ||
    'en';

  useEffect(() => {
    initializeStripeInstance();
    homeSecurityPortalApi
      .post(endpoints.setupIntentUrl)
      .then((res) => {
        const { client_secret } = res.data;
        setOptions({
          ...stripeOptions,
          clientSecret: client_secret,
          locale: stripeLocale as StripeElementLocale
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log('stripesetup err', err);
        setLoading(false);
      });
  }, []);

  if (loading || !stripe) return <h1>Loading...</h1>;
  return (
    <Stack sx={{ m: '0 5px' }}>
      <Elements stripe={stripe} options={options}>
        <StripeForm
          amount={amount}
          isUpgrade={isUpgrade}
          isFreeTrial={isFreeTrial}
          submitPurchaseRequest={submitPurchaseRequest}
          isCancellationAndRefundPolicyAccepted={
            isCancellationAndRefundPolicyAccepted
          }
        />
      </Elements>
    </Stack>
  );
}

export default PaymentScreenDemo;
