import envConfig from 'configs/envConfig';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';

export const LANGUAGES = [
  'en-US',
  'zh-Hans',
  'zh-CN',
  'zh-SG',
  'zh-HK',
  'zh-TW'
];

const chineseLanguageMapping = {
  'zh-CN': 'zh-Hans',
  'zh-SG': 'zh-Hans',
  'zh-HK': 'zh-Hans',
  'zh-TW': 'zh-Hans'
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(
    resourcesToBackend((language: string) => {
      const mappedLanguage =
        chineseLanguageMapping[
          language as keyof typeof chineseLanguageMapping
        ] || language;

      return import(`./locales/${mappedLanguage}/translation.json`);
    })
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(i18nextPlugin)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: 'en-US',
    fallbackLng: 'en-US',
    supportedLngs: LANGUAGES,
    debug: envConfig('env') !== 'prod',
    ns: ['translation'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;

export const getLanguage = (languageCode: string) => {
  switch (languageCode) {
    case 'en':
      return 'en-US';
    case 'zh':
      return 'zh-Hans';
    case 'es':
      return 'es-ES';
    case 'de':
      return 'de-DE';
    default:
      return 'en-US';
  }
};

export const stripeLocaleMapping = {
  'en-US': 'en',
  'zh-Hans': 'zh',
  'zh-CN': 'zh',
  'zh-SG': 'zh',
  'zh-HK': 'zh',
  'zh-TW': 'zh',
  'es-ES': 'es',
  'de-DE': 'de'
};
